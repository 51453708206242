<template>
    <v-dialog v-model="visible" persistent max-width="800px">
        <v-card>
            <v-card-title class="justify-center">
                <v-spacer/>
                <span>
                    Assistente Criativo
                </span>
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text
                align="center"
                justify="center"
                style="height: 500px; overflow-x: auto; padding-top: 20px;"
            >
                <v-row>
                    <v-col cols="12" sm="6">
                        <!-- Oferta ou Promoção -->
                        <v-text-field
                            v-model="oferta"
                            label="Oferta ou Promoção"
                            placeholder="Digite a oferta ou promoção"
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>

                    <v-col cols="12" sm="6">
                        <!-- Valor ou Porcentagem de Desconto -->
                        <v-text-field
                            v-model="desconto"
                            label="Valor ou Porcentagem de Desconto"
                            placeholder="Digite o valor ou porcentagem de desconto"
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="6">
                        <!-- Tom da Resposta -->
                        <v-select
                            v-model="tom_resposta"
                            :items="['Engraçado', 'Amigável', 'Elegante', 'Irreverente', 'Divertido', 'Sério', 'Intimo',]"
                            label="Tom da Resposta"
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>

                    <v-col cols="12" sm="6">
                        <!-- Humor -->
                        <v-select
                            v-model="humor"
                            :items="['Sutil', 'Moderado', 'Exagerado']"
                            label="Humor"
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="6">
                        <!-- Nível de Emojis -->
                        <v-select
                            v-model="nivel_emojis"
                            :items="['Sem emojis', 'Poucos emojis', 'Muitos emojis']"
                            label="Nível de Emojis"
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>

                    <v-col cols="12" sm="6">
                        <!-- Foco da Copy -->
                        <v-select
                            v-model="foco_copy"
                            :items="['Foco no desconto', 'Foco no produto', 'Foco na urgência', 'Foco na qualidade']"
                            label="Foco da Copy"
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="6">
                        <!-- Chamada de Ação -->
                        <v-select
                            v-model="estilo_cta"
                            :items="['Imperativa', 'Sugestiva', 'Engraçada', 'Criativa']"
                            label="Chamada de Ação"
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>

                    <v-col cols="12" sm="6">
                        <!-- Duração da Mensagem -->
                        <v-select
                            v-model="duracao_mensagem"
                            :items="['Curta e objetiva', 'Média', 'Longa']"
                            label="Duração da Mensagem"
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="6">
                        <!-- Referência a Datas Especiais ou Feriados -->
                        <v-text-field
                            v-model="referencia_data_especial"
                            label="Referência a Datas Especiais ou Feriados"
                            placeholder="Digite a data comemorativa"
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <!-- Ramo de Atuação -->
                        <v-text-field
                            v-model="ramo"
                            label="Qual seu tipo de estabelecimento"
                            placeholder="Digite o ramo de atuação"
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>
                </v-row>

                <div v-if="loading">
                    <div v-for="a in 3" :key="a">
                        <v-skeleton-loader
                            class="mx-auto"
                            max-width="600"
                            height="150"
                            type="card"
                        />
                        <div class="my-6"/>
                    </div>
                </div>

                <div v-if="!loading && messages.length">
                    <v-card
                        v-for="(message, key) in messages"
                        :key="key"
                        class="my-4"
                        dark
                        color="#97d999"
                        :style="{
                            backgroundImage: `url(${require('@/assets/images/wpp-background.jpg')})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }"
                    >
                        <v-card-subtitle style="color: white;">
                            {{ message }}
                        </v-card-subtitle>

                        <v-card-actions>
                            <v-spacer/>
                            <v-btn @click="selecionar(message)" color="primary" small>
                                Selecionar
                            </v-btn>
                            <v-spacer/>
                        </v-card-actions>
                    </v-card>
                </div>

                <v-btn @click="generate" :loading="loading" x-large color="primary" class="mt-6">
                    <v-icon class="mr-2">mdi-creation</v-icon>
                    Gerar COM IA
                </v-btn>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'IACopyGenerator',

    props: {
        visible: {
            type: Boolean,
            default: true,
        },
    },

    data: () => ({
        loading: false,
        messages: [],
        ramo: '',
        oferta: '',
        desconto: '',
        tom_resposta: '',
        humor: '',
        nivel_emojis: '',
        foco_copy: '',
        estilo_cta: '',
        duracao_mensagem: '',
        referencia_data_especial: '',
    }),

    computed: {
        getPrompt() {
            return `Meu negócio é: ${this.ramo || 'não especificada'}.
                A oferta é: ${this.oferta || 'não especificada'}.
                O desconto é de ${this.desconto || 'não especificado'}.
                O tom da resposta deve ser ${this.tom_resposta || 'não especificado'}.
                O nível de humor é ${this.humor || 'não especificado'}.
                O nível de emojis deve ser ${this.nivel_emojis || 'não especificado'}.
                O foco da copy será ${this.foco_copy || 'não especificado'}.
                A chamada para ação será ${this.estilo_cta || 'não especificada'}.
                A duração da mensagem deve ser ${this.duracao_mensagem || 'não especificada'}.
                Referência a datas especiais: ${this.referencia_data_especial || 'não especificada'}.`;
        },
    },

    methods: {
        generate() {
           this.loading = true;
            this.$http.post(`ia/campaign-message`, { prompt: this.getPrompt }).then(resp => {
                console.log(JSON.parse(resp.data.data))
                this.messages = JSON.parse(resp.data.data);
            }).catch((e) => {
                console.log(e)
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loading = false));
        },

        selecionar(message) {
            this.$emit('onSelect', message);
            this.$emit('close');
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
